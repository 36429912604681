import React, { useEffect, useState } from "react";
import {
  Descriptions,
  Divider,
  Card,
  Progress,
  Button,
  Form,
  Input,
  Radio,
  notification,
  Spin,
} from "antd";
import "./facilityDisplayStyles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getInitialSteps } from "./surveyInitialSteps";
import { getAdditionalSteps } from "./surveyAdditionalSteps";

const { TextArea } = Input;

const SurveyComponent = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);

  // From Redux
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const userEmail = useSelector((state) => state.auth.userEmail);
  const mmApiKey = useSelector((state) => state.auth.mmApiKey);

  // Coordinates for Google Maps
  const siteLat = selectedFacility?.latitude;
  const siteLong = selectedFacility?.longitude;

  // Construct the Google Maps URL (for display in a question)
  const googleMapsUrl =
    siteLat && siteLong
      ? `https://www.google.com/maps/search/?api=1&query=${siteLat},${siteLong}`
      : "https://maps.google.com";

  // Survey steps
  const initialSteps = getInitialSteps(googleMapsUrl);
  const additionalSteps = getAdditionalSteps();

  // (NEW) Google Place details
  const placeId = selectedFacility.google_place_id; // Example Place ID
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS;

  // ---------------------------------------
  // Initialize Steps
  // ---------------------------------------
  const calculateProgress = () => {
    const totalSteps = steps.length - 1;
    const progress = Math.floor(((current + 1) / totalSteps) * 100);
    return Math.min(progress, 100);
  };

  const getCompletionStep = () => ({
    title: "Completion",
    key: "completion",
    content: (
      <>
        <h2>Thank you for completing the survey!</h2>
        <Button type="primary" onClick={retakeSurvey}>
          Find another store
        </Button>
      </>
    ),
  });

  const retakeSurvey = () => {
    form.resetFields();
    setFormData({});
    setCurrent(0);
    initializeSteps();
    navigate(`/facilities`);
  };

  const initializeSteps = () => {
    let overallQuestionIndex = 0;

    // Process initial steps
    const processedSteps = initialSteps.map((step, stepIndex) => {
      const content = (
        <>
          {step.questions.map((question, questionIndex) => {
            overallQuestionIndex++;
            const radioFieldName = `question_${question.questionId}_answer`;
            const textFieldName = `question_${question.questionId}_text`;
            const followUpFieldName = `question_${question.questionId}_followUp`;

            return (
              <div key={`${stepIndex}_${questionIndex}`}>
                {/* Question Title */}
                <Form.Item>
                  <strong>{question.questionText}</strong>
                </Form.Item>

                {/* Yes/No Radio Button */}
                <Form.Item
                  name={radioFieldName}
                  rules={[{ required: true, message: "Please select Yes or No" }]}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* Conditional Text Box */}
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues[radioFieldName] !== currentValues[radioFieldName]
                  }
                >
                  {({ getFieldValue }) => {
                    const answer = getFieldValue(radioFieldName);
                    if (answer === "Yes") {
                      return (
                        <Form.Item name={textFieldName} label="Additional Comments">
                          <TextArea rows={3} placeholder="Optional comments..." />
                        </Form.Item>
                      );
                    } else if (answer === "No") {
                      if (question.followUp) {
                        return (
                          <Form.Item
                            name={followUpFieldName}
                            label={question.followUp.questionText}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <TextArea rows={3} placeholder="Please provide details..." />
                          </Form.Item>
                        );
                      } else {
                        return (
                          <Form.Item
                            name={textFieldName}
                            label="Please explain"
                            rules={[
                              {
                                required: true,
                                message: "This field is required when you select No",
                              },
                            ]}
                          >
                            <TextArea rows={3} placeholder="Required comments..." />
                          </Form.Item>
                        );
                      }
                    }
                    return null;
                  }}
                </Form.Item>
                <Divider />
              </div>
            );
          })}
        </>
      );

      return {
        title: step.title,
        key: `initialStep_${stepIndex + 1}`,
        content: content,
      };
    });

    // Add the entry question step
    overallQuestionIndex++;
    const entryStep = {
      title: "Entry Question",
      key: "entryQuestion",
      content: (
        <>
          <Form.Item
            name="entryAnswer"
            label="Were you able to enter the property?"
            rules={[{ required: true, message: "Please select Yes or No" }]}
          >
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ),
    };

    setSteps([...processedSteps, entryStep, getCompletionStep()]);
  };

  useEffect(() => {
    initializeSteps();
    // eslint-disable-next-line
  }, []);

  // ---------------------------------------
  // Submit to MicroMind
  // ---------------------------------------
  const submitSurveyData = async (data) => {
    try {
      const microMind = process.env.REACT_APP_MICRO_API_URL;
      const microMindKey = process.env.REACT_APP_MICRO_KEY;
      const response = await fetch(`${microMind}/api/submit-survey`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "x-api-key": microMindKey,
          "Content-Type": "application/json",
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to submit survey. Please try again."
        );
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error submitting survey data:", error);
      throw new Error(error.message || "Failed to submit survey. Please try again.");
    }
  };

 // Using the new Places API v1 style endpoint & headers
// This example retrieves: rating & user_ratings_total

// If you only need `rating`, here’s a simplified snippet using the Places API v1 endpoint.
// Example: fetch rating + userRatingCount from the Places API v1 endpoint
const fetchGoogleReviewData = async () => {
  if (!apiKey || !placeId) {
    return { rating: null, userRatingCount: null };
  }

  try {
    // Official v1 endpoint for Places
    const endpoint = `https://places.googleapis.com/v1/places/${placeId}`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": apiKey,
        // Use "X-Goog-FieldMask" for both fields, per the new v1 format
        "X-Goog-FieldMask": "rating,userRatingCount",
      },
    });

    if (!response.ok) {
      console.error("Places API request failed with status:", response.status);
      return { rating: null, userRatingCount: null };
    }

    const data = await response.json();
    console.log("Google fetch data JSON:", data);

    // The v1 response is typically top-level: { rating: 4.9, userRatingCount: 123, ... }
    const { rating, userRatingCount } = data;

    return {
      rating: rating ?? null,
      userRatingCount: userRatingCount ?? null,
    };
  } catch (error) {
    console.error("Error fetching Google rating & userRatingCount:", error);
    return { rating: null, userRatingCount: null };
  }
};





  // ---------------------------------------
  // (NEW) onFinish as an async function
  // ---------------------------------------
  const onFinish = async (finalData = formData) => {
    try {
      // 1. Validate required fields
      const values = await form.validateFields();
  
      // 2. Merge base data
      const visitDateTime = new Date().toISOString();
      const mmId = selectedFacility?.id || "default-facility-id";
      const nsaId = selectedFacility?.nsa_id;
      const site_lat = selectedFacility?.latitude;
      const site_long = selectedFacility?.longitude;
  
      let mergedData = {
        ...finalData,
        ...values,
        visitDateTime,
        mmId,
        nsaId,
        site_lat,
        site_long,
        userEmail, // from Redux
      };
  
      // 3. Optionally add user location from localStorage
      const userLocationString = localStorage.getItem("userLocation");
      let parsedUserLocation = null;
      if (userLocationString) {
        try {
          parsedUserLocation = JSON.parse(userLocationString);
        } catch (err) {
          console.error("Error parsing userLocation:", err);
        }
      }
      if (parsedUserLocation) {
        mergedData.user_lat = parsedUserLocation.lat;
        mergedData.user_long = parsedUserLocation.lon;
      } else {
        mergedData.user_lat = "not available";
        mergedData.user_long = "not available";
      }
  
      // (NEW) fetch rating + userRatingCount
      setLoading(true);
      const { rating, userRatingCount } = await fetchGoogleReviewData();
      
      // Attach them to mergedData
      mergedData.googleRating = rating;            // e.g. 4.9
      mergedData.googleReviewCount = userRatingCount; // e.g. 123
  
    
      // 5. Replace undefined/null values with empty strings
      const replaceUndefinedValues = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === undefined || obj[key] === null) {
            // skip user_lat / user_long if you want
            if (key !== "user_lat" && key !== "user_long") {
              obj[key] = "";
            }
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            replaceUndefinedValues(obj[key]);
          }
        });
      };
      replaceUndefinedValues(mergedData);
  
      // 6. Submit survey data
      await submitSurveyData(mergedData);
  
      notification.success({
        message: "Success",
        description: "Your survey has been submitted!",
      });
      setCurrent(steps.length - 1);
      setLoading(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
      setLoading(false);
    }
  };
  

  // ---------------------------------------
  // Next step logic
  // ---------------------------------------
  const next = () => {
    form.validateFields().then((values) => {
      const updatedFormData = { ...formData, ...values };
      setFormData(updatedFormData);

      const currentKey = steps[current]?.key;

      if (currentKey === "entryQuestion") {
        const entryAnswer = values.entryAnswer;
        if (entryAnswer === "Yes") {
          // If user can enter, add additional steps
          let overallQuestionIndex = Object.keys(updatedFormData).filter((key) =>
            key.includes("question_")
          ).length;

          const additionalProcessedSteps = additionalSteps.map((step, index) => {
            const content = (
              <>
                {step.questions.map((question, questionIndex) => {
                  overallQuestionIndex++;
                  const radioFieldName = `question_${question.questionId}_answer`;
                  const textFieldName = `question_${question.questionId}_text`;
                  const followUpFieldName = `question_${question.questionId}_followUp`;

                  return (
                    <div key={`additional_${index}_${questionIndex}`}>
                      <Form.Item>
                        <strong>{question.questionText}</strong>
                      </Form.Item>

                      <Form.Item
                        name={radioFieldName}
                        rules={[{ required: true, message: "Please select an option" }]}
                      >
                        <Radio.Group>
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                          {question.questionId === "Lighting" && (
                            <Radio value="N/A">N/A</Radio>
                          )}
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues[radioFieldName] !== currentValues[radioFieldName]
                        }
                      >
                        {({ getFieldValue }) => {
                          const answer = getFieldValue(radioFieldName);
                          if (answer === "Yes") {
                            return (
                              <Form.Item name={textFieldName} label="Additional Comments">
                                <Input.TextArea rows={3} placeholder="Optional comments..." />
                              </Form.Item>
                            );
                          } else if (answer === "No") {
                            if (question.followUp) {
                              return (
                                <Form.Item
                                  name={followUpFieldName}
                                  label={question.followUp.questionText}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <Input.TextArea rows={3} placeholder="Please provide details..." />
                                </Form.Item>
                              );
                            } else {
                              return (
                                <Form.Item
                                  name={textFieldName}
                                  label="Please explain"
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required when you select No",
                                    },
                                  ]}
                                >
                                  <Input.TextArea rows={3} placeholder="Required comments..." />
                                </Form.Item>
                              );
                            }
                          }
                          return null;
                        }}
                      </Form.Item>
                      <Divider />
                    </div>
                  );
                })}
              </>
            );

            return {
              title: `Step ${steps.length + index}`,
              key: `additionalStep_${index + 1}`,
              content: content,
            };
          });

          // Append additional steps + completion
          setSteps((prevSteps) => {
            const stepsUntilEntry = prevSteps.slice(0, current + 1);
            return [...stepsUntilEntry, ...additionalProcessedSteps, getCompletionStep()];
          });

          setCurrent(current + 1);
          form.resetFields();
        } else {
          // Could not enter -> submit immediately
          onFinish(updatedFormData);
        }
      } else {
        // Check if it's the last question step (before completion)
        if (current === steps.length - 2 && steps[steps.length - 1].key === "completion") {
          onFinish(updatedFormData);
        } else {
          setCurrent(current + 1);
          form.resetFields();
        }
      }
    });
  };

  const prev = () => {
    setCurrent(current - 1);
    form.setFieldsValue(formData);
  };

  // If steps not ready
  if (steps.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "0 16px" }}>
      <Progress percent={calculateProgress()} />

      <div className="steps-content" style={{ marginTop: 24 }}>
        <Form form={form} layout="vertical">
          {steps[current] && steps[current].content}
        </Form>
      </div>
      <div className="steps-action" style={{ marginTop: 24 }}>
        {current > 0 && steps[current]?.key !== "completion" && (
          <Button style={{ margin: "0 8px" }} onClick={prev}>
            Previous
          </Button>
        )}
        {steps[current] && steps[current].key !== "completion" && (
          <Button type="primary" onClick={next} loading={loading}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

const StoreAuditDisplay = () => {
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const { address, name } = selectedFacility || {};

  return (
    <>
      <Card title={name || "Loading..."}>
        <Descriptions title="Store Audit" column={3} bordered={false}>
          <Descriptions.Item label="Address">{address}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <SurveyComponent />
      </Card>
    </>
  );
};

export default StoreAuditDisplay;



// Justins endpoint he uses for google places, try this if you can't get the first one to work.
// https://places.googleapis.com/v1/places/${placeId}

// const response = await fetch(
//   `https://places.googleapis.com/v1/places/${placeId}`,
//   {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "X-Goog-Api-Key": apiKey,
//       "X-Goog-FieldMask":
//         "adrFormatAddress,location,googleMapsUri,utcOffsetMinutes",
//     },
//   },
// );

