import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice'; 
import { updateSelectedFacilityDetails, fetchFacilityDetails } from '../features/facilities/facilitiesSlice';
import store from "../redux/store"; 
import { useNavigate } from 'react-router-dom';
import * as unitsSlice from '../features/units/unitsSlice'; 
import * as engrainSlice from '../features/engrain/engrainSlice';
import { useSelector } from 'react-redux';

// import 'antd/dist/antd.css';
import './FacilitiesTable.css'; // Import your CSS file

const FacilitiesTable = ({ loading, facilities }) => {
  const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef();
  const navigate = useNavigate();
  const cookie = document.cookie;
  const loadingFacilities = useSelector(state => state.facilities.loadingFacilities);
  const loadingBiFacilities = useSelector(state => state.facilities.loadingBiFacilities);
  const isLoading = facilityDetailsLoading || loadingFacilities || loadingBiFacilities;


  const padNSAId = (id, length = 4) => {
    if (id == null || id.toString().trim().length === 0) {
      return id;
    }
    const idStr = id.toString();
    return idStr.padStart(length, '0');
  };

  const handleView = async (record) => {
    setFacilityDetailsLoading(true); 
    try {
      const facilityDetailsResponse = await store.dispatch(fetchFacilityDetails({ brand: record.brand, id: record.id, cookie })).unwrap();
      store.dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));
      await store.dispatch(engrainSlice.fetchUnits());
      await store.dispatch(engrainSlice.fetchFloors());
      await store.dispatch(unitsSlice.fetchUnits(record.id));
      setFacilityDetailsLoading(false); 
      navigate(`/facilities/${record.id}`);
    } catch (error) {
      console.error('Error fetching facility details:', error);
      setFacilityDetailsLoading(false);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      Object.values(record).some(
        item => item ? item.toString().toLowerCase().includes(value.toLowerCase()) : ''
      ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: text =>
      searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Store #',
      dataIndex: 'nsa_id',
      key: 'nsa_id',
      sorter: (a, b) => (a.nsa_id || '').toString().localeCompare((b.nsa_id || '').toString()),
      ...getColumnSearchProps('nsa_id'),
      render: (text, record) => padNSAId(record.nsa_id),
      responsive: ['sm'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ...getColumnSearchProps('name'),
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Brand Name',
      dataIndex: 'brand_name',
      key: 'brand_name',
      sorter: (a, b) => a.brand_name.length - b.brand_name.length,
      ...getColumnSearchProps('brand_name'),
      responsive: ['md'],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      responsive: ['lg'],
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ...getColumnSearchProps('city'),
      responsive: ['sm'],
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
      ...getColumnSearchProps('state'),
      responsive: ['sm'],
    },
    {
      title: 'Zip Code',
      dataIndex: 'zip_code',
      key: 'zip_code',
      ...getColumnSearchProps('zip_code'),
      responsive: ['md'],
    },
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
      ...getColumnSearchProps('email_address'),
      responsive: ['lg'],
    },
  ];

  return (
    <Table  
  className="small-font-table"
      onRow={(record) => ({
        onClick: () => handleView(record),
      })}
      dataSource={facilities.map(facility => ({
        ...facility,
        key: facility.id,
      }))}
      columns={columns}
      loading={{ indicator: <Spin />, spinning: isLoading }}
      scroll={{ x: 800 }} // Allows horizontal scroll on small screens
      pagination={{ pageSize: 10 }}
    />
  );
};

export default FacilitiesTable;
