import React, { useState, useEffect } from "react";
import { Modal, Divider, Switch, Form, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./FacilityImagesPage.css";
import {
  updateFacility,
  fetchBiFacilities,
} from "../features/facilities/facilitiesSlice"; // Adjust the import path as necessary

const imageCategories = [
  { name: "Beauty Shot", maxCount: 1 },
  { name: "Office", maxCount: 1 },
  { name: "Interior Hallway", maxCount: 1 },
  { name: "Exterior Driveway", maxCount: 1 },
  { name: "Security Screens", maxCount: 1 },
  { name: "Access Control", maxCount: 1 },
];

const FacilityImagesPage = () => {
  const dispatch = useDispatch();

  // Fetch biFacilities when the component is mounted
  useEffect(() => {
    dispatch(fetchBiFacilities());
  }, [dispatch]);

  const biFacilities = useSelector(
    (state) => state.facilities.biFacilities || []
  );
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [categoryFiles, setCategoryFiles] = useState({});
  const [loading, setLoading] = useState({}); // Manage the loading state for each facility

  useEffect(() => {
    const filesByFacility = {};
    biFacilities.forEach((facility) => {
      const filesByCategory = {};
      imageCategories.forEach((category) => {
        const categoryName = category.name.toLowerCase().replace(/\s+/g, "_");
        const url = `https://mm-facility-pics.s3.amazonaws.com/${facility.id}/${categoryName}`;
        filesByCategory[category.name] = [
          { uid: url, name: categoryName, url },
        ];
      });
      filesByFacility[facility.id] = filesByCategory;
    });
    setCategoryFiles(filesByFacility);
  }, [biFacilities]);

  const handlePreview = (image, title) => {
    setPreviewImage(image);
    setPreviewTitle(title);
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleToggleChange = async (checked, facilityId) => {
    setLoading((prev) => ({ ...prev, [facilityId]: true })); // Set loading state for the specific facility

    try {
      const updatedData = {
        facility: {
          custom: {
            enabledImageUpload: checked,
          },
        },
      };
      const actionResult = await dispatch(
        updateFacility({
          id: facilityId,
          data: updatedData,
          userRoles: roles,
          userEmail: email
        })
      );

      if (actionResult.error) {
        console.error("Update error:", actionResult.error);
        throw new Error("Update failed");
      }

      notification.success({
        message: "Success",
        description: `Photo upload has been ${checked ? "enabled" : "disabled"} successfully!`,
      });
    } catch (error) {
      console.error("Failed to update facility:", error);
      notification.error({
        message: "Error",
        description: `Failed to update facility: ${error.message}`,
      });
    } finally {
      setLoading((prev) => ({ ...prev, [facilityId]: false })); // Set loading state to false for the specific facility
    }
  };

  const renderImageAreas = (facilityId) => {
    return imageCategories.map((category) => {
      const fileList = categoryFiles[facilityId]?.[category.name] || [];
      return (
        <div key={category.name} className="image-area">
          <div className="category-title">{category.name}</div>
          <div className="image-row">
            {fileList.map((file) => (
              <img
                key={file.uid}
                src={file.url}
                alt={category.name}
                className="facility-image"
                onClick={() => handlePreview(file.url, category.name)}
              />
            ))}
          </div>
        </div>
      );
    });
  };

  const sortedFacilities = [...biFacilities]
    .filter((facility) => facility.business_status === "live") // Filter only live facilities
    .sort((a, b) => {
      if (a.brandName === b.brandName) {
        return a.id - b.id;
      }
      return a.brandName.localeCompare(b.brandName);
    });

  return (
    <>
      {sortedFacilities.map((facility) => (
        <div key={facility.id} className="facility-section">
          <Divider orientation="left"></Divider>
          <div>
            {(roles.includes("RegionalManager")||roles.includes("admin") || roles.includes("super_admin") || roles.toLowerCase()?.includes("operator")) && (
              <div className="centered-form-item">
                <div class="facility-address">
                  {facility.brand_name} | {facility.name} {facility.address}
                </div>
                <Form.Item label="Allow Photo Upload:" style={{margin:24}}>
                  <Switch
                    checked={facility.custom?.enabledImageUpload}
                    onChange={(checked) =>
                      handleToggleChange(checked, facility.id)
                    }
                    loading={loading[facility.id]}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                </Form.Item>
              </div>
            )}
            <div className="centered-form-item">
              <a href={facility.website}>{facility.website}</a>
            </div>
          </div>
          <div className="image-grid">{renderImageAreas(facility.id)}</div>
        </div>
      ))}
      <Modal
        width={1000}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default FacilityImagesPage;
