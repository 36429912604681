// surveyAdditionalSteps.js

// We don’t need googleMapsUrl here, so a simple array export is fine.
// If you ever need dynamic data, turn it into a function like in the initialSteps example.
export const getAdditionalSteps = () => [
    {
      title: "Step 6",
      questions: [
        {
          questionId: "OnPropertySignage",
          questionText:
            "Are the signs on property consistent with the NSA approved sign requirements?",
          followUp: {
            questionText:
              "What signage was found that doesn't meet brand standards?",
          },
        },
        {
          questionId: "SignageCondition",
          questionText:
            "Is the signage on the property visible, lit (where applicable), and in good condition?",
          followUp: {
            questionText: "What about the store signage was poor?",
          },
        },
      ],
    },
    {
      title: "Step 7",
      questions: [
        {
          questionId: "OfficeStandards",
          questionText:
            "Is the office Clean/Organized/Clutter Free/No personal items? And is the restroom is clean and stocked?",
          followUp: {
            questionText: "What about the office standards were poor?",
          },
        },
      ],
    },
    {
      title: "Step 8",
      questions: [
        {
          questionId: "Merchandise",
          questionText:
            "Is merchandise signage consistent with NSA branding, and is merchandise properly arranged, neat, and well stocked?",
          followUp: {
            questionText: "What about the merchandise display was poor?",
          },
        },
      ],
    },
    {
      title: "Step 9",
      questions: [
        {
          questionId: "DriveAisles/Hallways",
          questionText:
            "Are drive aisles and hallways clean and free of debri (doors, walls, floors clean)?",
          followUp: {
            questionText: "What about the drive aisles, hallways were poor?",
          },
        },
        {
          questionId: "Lighting",
          questionText:
            "Are interior lights operational, with no burned out bulbs, and if applicable, are exterior lights functional?",
          followUp: {
            questionText: "What about the lighting was poor?",
          },
          hasNAOption: true, // Example if you have an N/A option
        },
      ],
    },
    {
      title: "Step 10",
      questions: [
        {
          questionId: "Exit/Fire",
          questionText:
            "Are exit and emergency access points operational, with appropriate signage in place where needed?",
          followUp: {
            questionText:
              "What about the safety points of the property are concerning?",
          },
        },
      ],
    },
    {
      title: "Step 11",
      questions: [
        {
          questionId: "Employee",
          questionText:
            "Was the employee's uniform/attire well presented and within NSA standards?",
        },
      ],
    },
  ];
  