// surveyInitialSteps.js

// We'll export a function so we can dynamically inject the googleMapsUrl
export const getInitialSteps = (googleMapsUrl) => [
    {
      title: "Step 1",
      questions: [
        {
          questionId: "GoogleDirections",
          questionText: (
            <>
              Prior to arrival: Search for location in{" "}
              <a
                href={googleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Maps
              </a>{" "}
              &amp; click get directions. Does this bring you to the property
              entrance/office?
            </>
          ),
          followUp: {
            questionText:
              "Were you brought to the wrong address altogether, or was the map marker just not in the right spot on the property, where you were brought to the wrong property entrance?",
          },
        },
        {
          questionId: "QrCode",
          questionText:
            "Did the facility have a QR code requesting a Google review in a customer facing location?",
        },
        {
          questionId: "MainSignage",
          questionText:
            "Is the facility’s main signage (Pylon/Large Road View Signage) in good condition?",
          followUp: {
            questionText: "What about signage was in poor condition?",
          },
        },
      ],
    },
    {
      title: "Step 2",
      questions: [
        {
          questionId: "OfficeHours",
          questionText:
            "Are office hours consistent and accurate across signage and platforms (website, Google, MegaMind, etc)?",
          followUp: {
            questionText:
              "What platform or info told you the store should be open?",
          },
        },
        {
          questionId: "OfficeClosed",
          questionText: "Was the Office Operating in Designated Open Hours?",
        },
        {
          questionId: "ClosedSignage",
          questionText:
            "If closed, is there an NSA approved sign on the door clearly indicating closed status and provide customer direction for assistance?",
        },
      ],
    },
    {
      title: "Step 3",
      questions: [
        {
          questionId: "FirstImpression",
          questionText:
            "Is the parking lot, entrance, landscaping, trash enclosure clean and well kept?",
          followUp: {
            questionText: "What about the first impression was poor?",
          },
        },
      ],
    },
    {
      title: "Step 4",
      questions: [
        {
          questionId: "DoorDecals",
          questionText:
            "Does the first look on the front door and window meet NSA's brand expectations? When relevant, is the two-sided clock/sorry we missed you sign hanging?",
          followUp: {
            questionText: "What signs were and were not hanging?",
          },
        },
      ],
    },
  ];
  